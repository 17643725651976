body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F1F5F9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.copy-to-clipboard-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  background-color: #f1f1f7;
  padding: 10px;
  margin-bottom: 20px;
}

.copy-to-clipboard-button p {
  font-size: 35px;
  font-weight: 500;
  color: #1c2537;
  margin-right: 10px;
}

/* liner progress */

.css-eglki6-MuiLinearProgress-root {
  height: 6px !important;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

/* market page filter css */

.market-data-filter-box {
  cursor: pointer;
  padding: 10px 24px;
  border: 1px solid #F1F5F9;
  font-weight: bold
}

.market-data-filter-box:hover {
  background-color: rgb(244, 244, 244);
}

.market-data-filter-box.selected {
  background-color: rgb(244, 244, 244);
  color: black;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
  width: 250px !important;
}

.my-date-picker .css-10o2lyd-MuiStack-root>.MuiTextField-root, .css-10o2lyd-MuiStack-root>.MuiPickersTextField-root {
  min-width: 100% !important;
}

/* table header css */

.MuiDataGrid-columnHeader {
  background-color: #1976d2;
  color: white;
  font-weight: 900 !important;
  font-size: 16px;
}
