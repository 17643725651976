.layout-container {
  display: flex;
  min-height: 100vh;
  /* Ensure the container takes at least the full viewport height */
  font-family: Satoshi;
  height: 100%;
}

.sidebar {
  background-color: #1C2434;
  width: 250px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.logo {
  width: 30px;
  height: 30px;
}

.logo-container {
  position: absolute;
  top: 1.7rem;
  left: 2.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.company-name {
  margin-left: 1rem;
  color: #DEE4EE;
  font-size: 1.1rem;
  font-weight: bolder;
}

.sidebar-menu {
  color: #8A99AF;
  margin-top: 6rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.menu-header {
  font-weight: bolder;
}

.menu-section {
  margin-top: 1rem;
}

.menu-item {
  background-color: #333A48;
  display: flex;
  padding: 10px;
  align-items: center;
  cursor: pointer;
}

.menu-item span {
  font-weight: bold;
  color: #FFFFFF;
}

.menu-item span:hover {
  color: #FFFFFF;
}

.menu-icon {
  margin-right: 1rem;
}

.menu-expand-icon {
  margin-right: 1.5rem;
}

.dropdown-menu {
  display: none;
  margin-top: 0.5rem;
  cursor: pointer;
}

.menu-item+.dropdown-menu,
.dropdown-menu {
  display: block;
  max-height: 350px;
  overflow-y: auto;
}

.dropdown-item {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.dropdown-item:hover {
  color: #FFFFFF;
}

.menu-expand-icon {
  margin-left: auto;
}

.rotate-up {
  transform: rotate(180deg);
}

/*--- main content ---*/

.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: visible;
  /* Ensure content is visible */
}

.top-bar {
  position: fixed;
  top: 0;
  left: 250px;
  /* Ensure it aligns correctly with sidebar */
  right: 0;
  height: 80px;
  background: white;
  box-shadow: 0px 1px 0px #E2E8F0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1000;
}

.top-bar input[type="search"] {
  padding: 12px 15px 12px 35px;
  font-size: 1rem;
  border: 1px solid white;
  border-radius: 20px;
  color: #333;
  outline: none;
  width: 500px;
}

.top-bar input[type="search"]::placeholder {
  color: #aaa;
}

.top-bar input[type="search"]:focus {
  background-color: #f8f8f8;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2);
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.content {
  flex-grow: 1;
  background-color: rgb(255, 255, 255);
  margin-top: 80px;
  padding: 20px;
  overflow: visible;
  /* Ensure content is visible */
  display: flex;
  flex-direction: column;
}

.controls-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* Allows controls to wrap onto the next line if needed */
  align-items: center;
  gap: 1rem;
  /* Adjust the space between control groups */
  margin: 1.9rem auto;
  padding: 0 20px;
}

.dropdown-picker {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e2e8f0;
  border-radius: 4px;
  padding: 5px 15px;
  color: #64748B;
  justify-content: space-around;
  font-size: 1rem;
}

.days-out-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.days-range-label {
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  color: #64748B;
  font-size: 1rem;
}

.days-out-control {
  display: flex;
  gap: 10px;
}

.days-out-control .input-group {
  display: flex;
  flex-direction: column;
}

.days-out-control input {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 20px 10px;
  color: #64748B;
  width: 100px;
  font-size: 1rem;
}

.days-out-container.disabled {
  opacity: 0.5;
}

.days-out-container.disabled .days-out-control {
  pointer-events: none;
}

.control-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Align items to the start of the container */
  margin-right: 0.5rem;
  /* Space between control groups */
  min-width: 120px;
  /* Set a minimum width for smaller controls */
  max-width: 300px;
  /* Set a maximum width for larger controls */
}

.control-label {
  color: #64748B;
  font-size: 1rem;
  margin-bottom: 8px;
  /* Adjust the space between label and control */
  text-align: center;
  /* Center the label text */
  width: 100%;
  /* Ensure label takes full width of its container */
}

.dropdown-picker {
  width: 100%;
  /* Make controls expand to fill their container */
  padding: 17px;
}

/* Existing Styles for Disabled State */
.days-out-container.disabled,
.control-group.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.date-range-picker {
  justify-content: space-between;
  /* Adjust as needed */
  padding: 0 17px;
}

/* Style adjustments for Days Out Range input */
.days-out-control input {
  padding: 18px;
  /* Adjust padding if needed */
  font-size: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  color: #64748B;
}

.days-range-label {
  align-self: center;
  /* Center the label */
  text-align: center;
  /* Ensure the text is centered in the label */
  margin-bottom: 8px;
  /* Space between label and control */
}

/* Adjustments if you have specific styling for your DatePicker component */
.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Align the DatePicker to the center of its container */
  margin-right: 20px;
  /* Space between the DatePicker and the next control */
}

.date-picker-popup {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-picker-popup-item {
  color: #333;
  font-weight: bold;
  margin: 5px;
}

/*--- chart ---*/

.chart-container {
  width: 100%;
  max-width: 78vw;
  height: 400px;
  margin: auto;
  padding: 20px;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  background-color: white;
}

.graph-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.marketing-chart-container {
  display: flex;
  justify-content: center;
  /* Center the items horizontally */
  align-items: flex-start;
  /* Align items to the top */
  gap: 20px;
  /* Space between each chart */
  margin: 20px 20px;
  /* Vertical spacing for the whole container */
}

.individual-chart {
  flex: 1;
  /* Each chart will take up equal space */
  max-width: calc(50% - 20px);
  /* Subtract the gap from the width */
  height: 400px;
  /* Height can be auto or a fixed value */
  padding: 20px;
  /* Padding inside each chart */
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  /* Optional shadow */
  border-radius: 4px;
  /* Border radius for aesthetics */
  background-color: white;
  /* Background color */
}

/*--- MarketPage ---*/

.market-header {
  color: #1C2434;
  text-align: center;
  text-transform: capitalize;
}

.map-container {
  width: 100%;
  max-width: 97%;
  height: 100%;
  margin: auto;
  /* margin-bottom: 10px; */
  margin-top: 20px;
  padding: 20px 20px 40px 20px;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  background-color: white;
}

/*--- Main Map ---*/

.map-controls {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 80vw;
  align-items: center;
  margin: 1.9rem auto;
  gap: 0.5rem;
}

.map-controls button {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e2e8f0;
  border-radius: 4px;
  padding: 15px 15px;
  color: #64748B;
  justify-content: space-around;
  font-size: 1rem;
}

/*--- Forecast ---*/

.content-area {
  margin-left: 20px;
  /* Adjust as needed */
  /* max-width: 80%; */
  max-height: 1400px;
  overflow: scroll;
}

.date-filter {
  margin: 20px 0;
}

#date-slider {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

label[for="date-slider"] {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f5f5f5;
  color: #333;
}

td {
  color: #666;
}

tr:hover {
  background-color: #f5f5f5;
}

th:first-child,
td:first-child {
  font-weight: bold;
}

.event-id-column {
  min-width: 275px;
  position: sticky;
  left: 0;
  z-index: 100;
  background-color: #F1F5F9;
}

.event-id-column th {
  background-color: #f5f5f5;
}

.total-tickets {
  min-width: 100px;
}

.adjust-buttons {
  border: 1px solid #666;
  background-color: transparent;
  color: #1C2434;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.adjust-buttons:disabled {
  background-color: #ccc;
}

.adjust-percentage {
  margin: 0 5px;
  font-weight: bold;
  color: #666;
}

.table-cell {
  min-width: 140px;
}

.export-container {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  /* Allows controls to wrap onto the next line if needed */
  align-items: center;
  gap: 1rem;
  /* Adjust the space between control groups */
  margin: 1.9rem auto;
  padding: 0 20px;
}

.export-container button {
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 10px 10px;
  color: #64748B;
  width: 150px;
  font-size: 1rem;
}

.marketing-chart-container {
  width: 100%;
  max-width: 78vw;
  height: 500px;
  padding: 20px;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  background-color: white;
}

.marketing-chart-container h5 {
  text-align: center;
}

#marketing-data-num-days {
  padding: 18px;
  /* Adjust padding if needed */
  font-size: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  color: #64748B;
  margin: 1rem 1.3rem;
}

.marketing-control-label {
  color: #64748B;
  font-size: 1rem;
  margin-bottom: 8px;
  /* Adjust the space between label and control */
  margin-left: 1.3rem;
  text-align: center;
  /* Center the label text */
  width: 100%;
  /* Ensure label takes full width of its container */
}

/* Competition File CSS */

.add-event-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 20px 30px;
}