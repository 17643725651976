/* Apply the gradient background to the whole page */
.login-container {
  font-family: 'Satoshi';
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1C2434;
  position: relative;
  overflow: hidden;
}

.login-card {
  background: rgba(255, 255, 255, 0.1);
  /* Lower the opacity */
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(26px);
  /* Adjust the blur intensity */
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* Optional: subtle border for defined edges */
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  text-align: center;
  margin: auto;
  position: relative;
  z-index: 1;
  /* Ensures card is centered in the .login-container */
}

/* Style the form elements */
.login-card form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-card input {
  width: calc(100% - 20px);
  /* Subtracts padding from width to maintain alignment */
  padding: 10px;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  /* Adds a border for clearer input fields */
  border-radius: 10px;
  box-shadow: none;
  /* Removed inset shadow for a flatter design */
  outline: none;
  /* Removes the outline to keep the design clean */
}

.login-card input::placeholder {
  color: #aaa;
  /* Placeholder text color */
}

.login-card button {
  width: calc(100% - 20px);
  /* Matches input width */
  padding: 10px;
  margin-top: 10px;
  /* Space between the last input and button */
  border: none;
  border-radius: 20px;
  background-color: #0A0123;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-card button:hover {
  background-color: #2ecc71;
}

/* Additional styling as needed */
.login-card h2 {
  margin-bottom: 1em;
  font-weight: bold;
  color: white;
}

.svg-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.signup-disable {
  background-color: grey !important;
} 

.signup-enable {
  background-color: black !important;
}

.signup-enable:hover {
  background-color: #2ecc71 !important;
}