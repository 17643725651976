.box-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: white;
  box-shadow: 0px 8px 13px -3px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  border: 1px solid #E2E8F0;
  margin-top: 20px;
}

.box-section {
  flex: 1;
  border-right: 1px solid #E2E8F0;
  padding: 1rem;
  margin: .75rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.box-section:last-child {
  border-right: none;
}

.metric-row {
  display: flex;
  align-items: center;
}

.main-metric {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 0.5rem;
}

.indicator {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.percentage {
  font-size: 1rem;
  color: #10B981;
}

.percentage-down {
  font-size: 1rem;
  color: #F0950C;
}

.label {
  font-size: 1rem;
  color: #64748B;
  margin-top: 0.5rem;
}